<template>
  <div>
    <app-collapse accordion>
      <app-collapse-item :isVisible="true" headerBgVariant="info">
        <template slot="header">
          <b-card-title class="text-white">
            <feather-icon
              size="24"
              class="mr-1 text-white"
              icon="ShoppingCartIcon"
            />Quản lý Lớp Học/Khóa Học</b-card-title>
        </template>
        <!-- Items Section -->
        <div
          ref="form"
          class="ft-filter-form"
        >
          <b-row>
            <!-- Single Item Form Headers -->
            <b-col md="12" id="product-info-list">
              <b-row>
                <b-col cols="12">
                  <div style="margin-bottom: 10px;text-align: center">
                    <b-button v-b-modal.modal-choose-khoa-hoc variant="warning" size="sm"><feather-icon icon="AlignJustifyIcon"/>Chọn Lớp Học /Khóa Học</b-button>
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-table :items="productData.items" :fields="classFields">
                    <template #cell(act)="data">
                      <b-button size="sm" @click="showMsgBoxConfirmDelete(data.index)" variant="warning"><feather-icon icon="XIcon" /></b-button>
                    </template>
                    <template #cell(price)="data">
                      {{ FormatPrice(data.item.price) }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!--<div class="d-flex flex-column justify-content-between border-left py-50 px-25">
            <feather-icon
              size="16"
              icon="XIcon"
              class="cursor-pointer"
              @click="removeItem(index)"
            />
            <feather-icon
              :id="`form-item-settings-icon-${index}`"
              size="16"
              icon="SettingsIcon"
              class="cursor-pointer"
            />
            <b-popover
              :ref="`form-item-settings-popover-${index}`"
              :target="`form-item-settings-icon-${index}`"
              triggers="click"
              placement="lefttop"
            >
              <b-form @submit.prevent>
                <b-row>

                  <b-col cols="12">
                    <b-form-group
                      label="Discount(%)"
                      :label-for="`setting-item-${index}-discount`"
                    >
                      <b-form-input
                        :id="`setting-item-${index}-discount`"
                        :value="null"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group
                      label="Tax 1"
                      :label-for="`setting-item-${index}-tax-1`"
                    >
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :value="'10%'"
                        :options="['0%', '1%', '10%', '14%', '18%']"
                        :input-id="`setting-item-${index}-tax-1`"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group
                      label="Tax 2"
                      :label-for="`setting-item-${index}-tax-2`"
                    >
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :value="'10%'"
                        :options="['0%', '1%', '10%', '14%', '18%']"
                        :input-id="`setting-item-${index}-tax-2`"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    class="d-flex justify-content-between mt-1"
                  >
                    <b-button
                      variant="outline-primary"
                      @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                    >
                      Apply
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-popover>
          </div>-->
        </div>
      </app-collapse-item>
    </app-collapse>
    <choose-khoa-hoc @on-submit="onSelectedKhoaHoc" :selectedRows="this.productData.items" />
  </div>
</template>

<script>
import {BTable, BInputGroup, BInputGroupPrepend,BCardTitle, BRow, BCol,BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { ValidationProvider } from 'vee-validate'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ChooseKhoaHoc from '../modal/ChooseKhoaHoc.vue'
import { formatPrice } from '@/@core/utils/filter'
export default {
  props: {
    hocVien: {
      type: Object,
    },
    phuHuynh: {
      type: Object,
    },
    productData: {
      type: Object,
    },
  },
  methods: {
    FormatPrice(price) {
      return formatPrice(price)
    },
    addNewItemInItemForm() {
      const item = {}
      item.id = this.nextId + 1
      this.nextId = item
      item.khoa_hoc = []
      this.items.push(item)
      this.$emit('new-class', item)
    },
    removeItem(i) {
      this.items = this.items.filter((item, k) => k !== i)
    },
    onSelectedKhoaHoc(its) {
      // cong tien vào trả trước
      // kiểm tra dòng đã tồn tại chưa?
      this.productData.items = [...its, ...this.productData.items]
      const total = its.reduce((a, b) => +a + +b.price, 0)
      console.log('total money on selected', total)
      this.$emit('update-money', total)
    },
    showMsgBoxConfirmDelete(id) {
      // delete row
      this.$bvModal
        .msgBoxConfirm('Có phải bạn muốn xóa không?', {
          title: 'Xác nhận',
          size: 'md',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            // xóa dòng
            //trừ tiền

            this.productData.items = this.productData.items.filter((obj,k ) => k !== id)
          }
        })
    },
  },
  data() {
    return {
      classFields: [
        {
          key: 'khoa_hoc',
          label: 'Khóa Học',
        },
        {
          key: 'lop_hoc',
          label: 'Lớp Học',
        },
        {
          key: 'giao_vien',
          label: 'Giáo Viên',
        },
        {
          key: 'thoi_gian',
          label: 'Thời Gian',
        },
        {
          key: 'price',
          label: 'Giá',
        },
        {
          key: 'act',
          label: '',
        },
      ],
      idSelected: null,
      nextId: 2,
      items_khoa_hoc: [],
      items: [],
      invoiceData: {
        id: 5037,
        client: null,

        // ? Set single Item in form for adding data
        items: [],

        salesPerson: '',
        paymentMethod: null,
      },
    }
  },
  components: {
    ValidationProvider,
    BTable,
    ChooseKhoaHoc,
    BButton,
    BInputGroupPrepend,
    AppCollapse,
    AppCollapseItem,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    FeatherIcon,
    BInputGroup,
  },
}
</script>

<style>
  #product-info-list > .item-product {
    border: 1px solid rgb(185, 180, 180);
    margin-bottom: 5px;
    padding: 10px 5px 0px 10px;
    background-color: beige;
    box-shadow: 1px 1px 1px rgb(116, 114, 114);
  }
  .ft-button-class-act-add {
    padding-top: 10px;
    padding-left: 4px;
  }
  .ft-loop-khoa-hoc {
    margin-bottom: 10px;
  }
  .ft-thong-tin-hoc-vien {
    border: 2px solid #441bba;
    border-radius: 10px;
    position: relative;
  }
  .ft-thong-tin-hoc-vien .thong-tin-title {
    position: absolute;
    top: -11px;
    background-color: #fff;
    font-weight: bold;
  }
  .ft-thong-tin-hoc-vien .des {
    margin-top: 15px;
  }
  .ft-loop-khoa-hoc .th-selected-khoa {
    padding-left: 5px;
    font-size: .8rem;
    font-weight: bold;
    margin-bottom: -5px;
  }
</style>