<template>
  <div>
    <b-modal
      id="modal-choose-khoa-hoc"
      :title="'Thêm Khóa Lớp Học'"
      size="lg"
      ok-title="Đã Chọn Xong"
      cancel-title="Hủy"
      @ok="onSubmit"

    >
      <b-row>
        <b-col cols="12">
          <table class="table table-bordered">
            <tbody>
              <td style="width: 20%">
                <b-form-group>
                  <b-form-input
                    class="ft-search-form-them-khoa-hoc"
                    placeholder="Tìm Khóa Học"
                    @keyup="columnFilter('khoa_hoc')"
                    v-model="filterKhoaHoc"
                  />
                </b-form-group>
              </td>
              <td style="width: 20%">
                <b-form-group>
                  <b-form-input
                    class="ft-search-form-them-khoa-hoc"
                    placeholder="Tìm Lớp Học"
                    @keyup="columnFilter('lop_hoc')"
                    v-model="filterLopHoc"
                  />
                </b-form-group>
              </td>
              <td style="width: 20%">
                <b-form-group>
                  <b-form-input
                    class="ft-search-form-them-khoa-hoc"
                    placeholder="Tìm Giáo Viên"
                    @keyup="columnFilter('giao_vien')"
                    v-model="filterGiaoVien"
                  />
                </b-form-group>
              </td>
              <td>
                <b-form-group>
                  <b-form-datepicker
                    v-model="filterDateFrom"
                    @input="columnFilter('tu_ngay')"
                    placeholder="Từ Ngày"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                  />
                </b-form-group>
              </td>
              <td>
                <b-form-group>
                  <b-form-datepicker
                    v-model="filterDateTo"
                    @input="columnFilter('den_ngay')"
                    placeholder="Đến Ngày"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                    :date-disabled-fn="dateDisabled"
                  />
                </b-form-group>
              </td>
            </tbody>
          </table>
        </b-col>
        <b-col cols="12" v-if="count > 0" style="margin-bottom: 10px;">
          <div class="ft-khoa-hoc-modal-selected-count">Đã chọn được: <b>{{ count }}</b> Lớp Học/Khóa Học</div>
        </b-col>
        <b-col cols="12">
          <b-table
            :items="rows"
            :fields="fields"
            selectable
            @row-selected="onRowSelected"
            :filter-included-fields="filterOn"
            :filters="filter"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            @filtered="onFiltered"
          >
            <template #cell(selected)="{ rowSelected }">
              <span v-if="rowSelected" style="color: green">
                <feather-icon icon="CheckIcon" />Đã chọn
              </span>
            </template>
            <template #cell(thoi_gian)="data">
                {{ data.item.thoi_gian }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BCol, BRow, BFormDatepicker, BFormGroup, BFormInput, BTable, BModal } from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

export default {
  props: {
    selectedRows: {
      type: Array,
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'modal-choose-khoa-hoc') {
        this.selected = []
        this.count = 0
      }
    })
  },
  methods: {
    getDateByRow(row) {
      let date = new Date(row*1000)
      return date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear()
    },
    dateDisabled(ymd, date) {
      const day = date.getDate()
      return day < new Date(new Date(this.filterDateFrom).getDate())
    },
    columnFilter(t) {
      this.filterOn = [t]
      // eslint-disable-next-line default-case
      switch (t) {
        case 'khoa_hoc' :
        {
          this.filter = this.filterKhoaHoc
        }
        break;
        case 'lop_hoc' :
        {
          this.filter = this.filterLopHoc
        }
        break;
        case 'giao_vien' :
        {
          this.filter = this.filterGiaoVien
        }
        break;
      }
    },
    onFiltered(filteredItems) {
      // console.log('onFilter', filteredItems)
      // this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(rows) {
      this.count = rows.length
      this.selected = rows
    },
    onSubmit() {
      this.$emit('on-submit', this.selected)
    },
  },
  computed: {
    rows() {
      if (this.selectedRows) {
        console.log('selectedRows', this.selectedRows)
        return this.items.filter(item => !this.selectedRows.includes(item))
      }else if (this.filterDateFrom && this.filterDateTo) {
        const start = Math.round(new Date(this.filterDateFrom).getTime()/1000)
        const end = Math.round(new Date(this.filterDateTo).getTime()/1000)
        return this.items.filter(item => {
          const date = item.timestamp
          return date >= start && date <= end
        })
      }
      return this.items
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterKhoaHoc: null,
      filterLopHoc: null,
      filterGiaoVien: null,
      filter: null,
      filterOn: ['khoa_hoc'], // danh sách cột
      count: 0,
      filterDateFrom: null,
      filterDateTo: null,
      selected: [],
      items: [
        {
          id: 1,
          khoa_hoc: 'Khóa 1',
          lop_hoc: '1',
          giao_vien: 'XXXXX',
          thoi_gian: '20/07/2022',
          timestamp: 1658755234,
          hoc_sinh_toi_da: 20,
          hoc_sinh_da_co: 2,
          price: 100000,
        },
        {
          id: 2,
          khoa_hoc: 'Khóa 2',
          lop_hoc: '2',
          giao_vien: '22222',
          thoi_gian: '20/07/2022',
          timestamp: 1658755234,
          hoc_sinh_toi_da: 20,
          hoc_sinh_da_co: 2,
          price: 100000,
        },
        {
          id: 3,
          khoa_hoc: 'Khóa 4',
          lop_hoc: '2',
          giao_vien: '44444',
          thoi_gian: '20/07/2022',
          timestamp: 1658755234,
          hoc_sinh_toi_da: 20,
          hoc_sinh_da_co: 2,
          price: 100000,
        },
        {
          id: 4,
          khoa_hoc: 'Khóa 3',
          lop_hoc: '2',
          giao_vien: 'Da5555',
          thoi_gian: '20/07/2022',
          timestamp: 1658755234,
          hoc_sinh_toi_da: 20,
          hoc_sinh_da_co: 2,
          price: 100000,
        },
        {
          id: 5,
          khoa_hoc: 'Khóa 5',
          lop_hoc: '2',
          giao_vien: 'Da66',
          thoi_gian: '20/07/2022',
          timestamp: 1658755234,
          hoc_sinh_toi_da: 20,
          hoc_sinh_da_co: 2,
          price: 100000,
        },
        {
          id: 6,
          khoa_hoc: 'Khóa 6',
          lop_hoc: '2',
          giao_vien: '77777',
          thoi_gian: '20/07/2022',
          timestamp: 1658755234,
          hoc_sinh_toi_da: 20,
          hoc_sinh_da_co: 2,
          price: 100000,
        },
      ],
      fields: [
        {
          key: 'selected',
          label: 'Chọn',
          thStyle: {
            'min-width': '150px',
          }
        },
        {
          key: 'khoa_hoc',
          label: 'Khóa Học',
        },
        {
          key: 'lop_hoc',
          label: 'Lớp Học',
        },
        {
          key: 'giao_vien',
          label: 'Giáo Viên',
        },
        {
          key: 'thoi_gian',
          label: 'Thời Gian',
        },
        {
          key: 'hoc_sinh_toi_da',
          label: 'Học Sinh Tối Đa',
        },
        {
          key: 'hoc_sinh_da_co',
          label: 'Học Sinh Đã Có',
        },
        {
          key: 'price',
          label: 'Giá',
        },
      ],
    }
  },
  components: {
    BCol,
    BRow,
    BFormDatepicker,
    BModal,
    BTable,
    FeatherIcon,
    BFormGroup,
    BFormInput,
  },
}
</script>

<style>
  table tbody td .form-group {
    margin-bottom: unset !important;;
  }
  .ft-khoa-hoc-modal-selected-count {
    font-size: 1.2rem;
    color: green;
  }
</style>