<template>
  <b-card no-body>
    <b-card-header style="background-color: #d2d6de" header-class="ft-card-header-create-user">
      <b-card-title><feather-icon
          size="24"
          class="mr-1"
          icon="ClipboardIcon"
        />Đã Thanh Toán (vnđ)</b-card-title>
      <b-button
        v-b-modal.modal-2
        size="sm"
        variant="primary"
        style="margin-right: 10px;"
        :disabled="money.toMoney < 0"
      >
        <feather-icon
          icon="CreditCardIcon"
          class="mr-50  text-white"
        />
        <span class="align-middle text-white">Thanh Toán</span>
      </b-button>
    </b-card-header>
    <b-card-body> {{ /* class="ft-filter-form" */}}
      <b-row>
        <b-col>
          <b-alert variant="warning">Bạn phải đăng ký khóa học mới có thể trả trước</b-alert>
        </b-col>
        <b-col v-for="(value, name, index) in money.moneyType" cols="12" :key="index">
          <b-form-group
            :label="getNameBill(name)"
            label-for="money_received"
            label-cols-md="4"
          >
            <cleave
              :value="value"
              class="form-control"
              disabled
              :raw="false"
              :options="{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
              }"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Tổng Tiền Đã Trả"
            label-cols-md="4"
          >
            <cleave
              v-model="money.paid"
              class="form-control"
              disabled
              :raw="true"
              :options="{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
              }"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

import { BAlert, BRow, BCol, BCard, BFormGroup, BButton, BCardHeader, BCardTitle, BCardBody } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  methods: {
    getNameBill(name) {
      return this.loaiChuyenKhoang.map(e => {
        if (e.value == name) {
          return 'Đã Trả ' + e.text
        } else {
          return ''
        }
      })
    }
  },
  props: {
    loaiChuyenKhoang: {
      type: Array,
      required: true,
    },
    money: {
      type: Object,
      required: true,
    },
    openAddPay: { // đã có sản phẩm
      type: Boolean,
      default: true,
    },
  },
  components: {
    BAlert,
    Cleave,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
}
</script>
<style scoped>
  .form-control {
    text-align: right;
  }
</style>