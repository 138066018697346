<template>
  <app-collapse accordion>
    <app-collapse-item :isVisible="true" headerStyle="background-color: rgb(184 215 224);">
      <template slot="header">
        <div class="d-flex align-items-center">
          <b-card-title class="ml-25">
            <feather-icon
              size="24"
              class="mr-1"
              icon="InfoIcon"
            />
            <span>Thông Tin Đăng nhập</span>
          </b-card-title>
        </div>
      </template>
      <div class="ft-filter-form">
        <b-row>
          <b-col cols="12">
            <b-form-group
                label="Tài Khoản Học Viên"
                label-cols="4"
              ><validation-provider
              #default="{ errors}"
              name="accountStudentUser"
              :rules="allowValidate ? '': 'required'"
              :customMessages="{['required']: 'Không được để trống'}"
            > 
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="accountInfo.studentAccount.username"
                    :state="errors.length > 0 ? false: null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0]}}</small>
            </validation-provider>
              </b-form-group>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors}"
              name="accountStudentPass"
              :rules="allowValidate ? '': 'required'"
              :customMessages="{['required']: 'Không được để trống'}"
            >
              <b-form-group
                label="Mật Khẩu Học Viên"
                label-cols="4"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    type="password"
                    v-model="accountInfo.studentAccount.password"
                    :state="errors.length > 0 ? false: null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0]}}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" v-if="parentStatus">
            <validation-provider
              #default="{ errors}"
              name="accountParent"
              :rules="allowValidate ? '': 'required'"
              :customMessages="{['required']: 'Không được để trống'}"
            >
              <b-form-group
                label="Tài Khoản Phụ Huynh"
                label-cols="4"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    type="password"
                    v-model="accountInfo.parentAccount.username"
                    :state="errors.length > 0 ? false: null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0]}}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" v-if="parentStatus">
            <validation-provider
              #default="{ errors}"
              name="passwordParent"
              :rules="allowValidate ? '': 'required'"
              :customMessages="{['required']: 'Không được để trống'}"
            >
              <b-form-group
                label="Mật Khẩu Phụ Huynh"
                label-cols="4"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    type="password"
                    v-model="accountInfo.parentAccount.username"
                    :state="errors.length > 0 ? false: null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0]}}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </div>
    </app-collapse-item>
  </app-collapse>
</template>

<script>

import { BInputGroup, BInputGroupPrepend, BFormInput, BCardTitle, BRow, BCol, BFormGroup,} from 'bootstrap-vue'
import constants from '@core/app-config/constants'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  props: {
    allowValidate: {
      type: Object,
    },
    accountInfo: {
      type: Object,
    },
    parentStatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSelectedStatus(val) {
      this.form.status = val
      this.$emit('on-data-update', val)
    },
  },
  data() {
    return {
      form: {
        manager_order: '',
        status: {},
        status_color: '',
        status_id: null,
        creater_order: null,
        mkt_source: null,
        type_order: null,
        phan_loai_sp: null,
        giao_hang: null,
      },
    }
  },
  created() {
    this.form.status = this.statusOptions[0].data[1]
  },
  computed: {
    Option() {
      return constants
    },
  },
  components: {
    ValidationProvider,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
  },
}
</script>

<style>

</style>