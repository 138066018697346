<template>
  <b-card no-body class="ft-card-not-border">
    <b-card-header class="ft-card-header-create-user">
      <b-card-title>
        <feather-icon icon="FileIcon" size="24" />Tạo thông tin khách hàng</b-card-title>
      <b-button variant="success" @click="createOrder"><feather-icon icon="SaveIcon" />Lưu Lại</b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
          <b-col cols="9">
            <validation-observer ref="CreateOrder">
            <b-row>
              <b-col cols="8">
                <b-row>
                  <b-col cols="12" class="ft-create-customer-bottom">
                    <!-- student -->
                    <create-customer-vue
                      :form="users.InfoForm"
                      @on-form-change="formInfoUserChange('hoc_vien', $event)"
                      @type-user="typeOptions"
                    />
                  </b-col>
                  <b-col cols="12" style="margin-top: 20px">
                    <!-- teacher -->
                    <create-customer-vue
                      v-if="users.InfoForm.parent"
                      :form="users.parentForms"
                      @on-form-change="formInfoUserChange('phu_huynh', $event)"
                      @type-user="typeOptions"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4">
                <order-info-vue
                  :statusOptions="statusOptions"
                  :form="users.infoOrder"
                  :data="data.orderInfo"
                />
                <!-- thông tin đăng nhập -->
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <!--khoa hoc lop hoc -->
                <product-info-vue
                  @update-money="onPriceSelectedClass"
                  @remove-item-khoa-hoc="productInfoRemoveItemKhoaHoc"
                  :productData="users.courses"
                />
              </b-col>
              <b-col cols="12">
                <!--khoa hoc lop hoc -->
                <div style="margin-top: 20px">
                  <account-form
                  :parent-status="users.InfoForm.parent"
                  :allow-validate="users.InfoForm.codeReg"
                  :accountInfo="users.account" />
                </div>
              </b-col>
              <b-col cols="12">
                <!--khoa hoc lop hoc -->
                <div style="margin-top: 20px">
                  <history-all :items="historyData"/>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
          </b-col>
        <b-col cols="3">
          <!-- chi phi-->
          <cost-vue :money="users.cost" />
          <!-- thanh toan truoc -->
          <pre-pay
            :money="users.cost"
            :loaiChuyenKhoang="typePayment"
          />
          <!-- lịch hẹn -->
          <book :items="users.book" />
          <!-- thông tin đơn hàng-->
        </b-col>
      </b-row>
      <!-- modal thanh toan-->
      <pay-bill
        @save-amount="onPaidSubmit"
        :loaiChuyenKhoang="typePayment"
      />
    </b-card-body>
  </b-card>
</template>

<script>

import { BCardBody, BRow, BCol, BCard, BCardHeader, BCardTitle, BButton } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import OrderInfoVue from './components/forms/OrderInfo.vue'
import ProductInfoVue from './components/forms/ClassProduct.vue'
import CostVue from './components/forms/Cost.vue'
import PrePay from './components/forms/PrePay.vue'
import AccountForm from './components/forms/Account.vue'
import Book from './components/forms/Book.vue'
import { required, email } from '@validations'
import CreateCustomerVue from './components/forms/createCustomer.vue'
import PayBill from './components/modal/PayBill.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import HistoryManager from './components/forms/HistoryManager.vue'

import HistoryAll from '../components/HistoryAll.vue'
export default {
  data() {
    return {
      data: {
        historyData: [
          {
            created_at: new Date().getTime(),
            user_edit: 'Danh Sinh',
            info_edit: 'Vừa chỉnh sửa',
          },
        ],
        orderInfo: {}
      },
      statusOptions: [{
        level: 0,
        data: [
          {
            id: 1, name: 'Chưa Xác Nhận', color: '#76ec39',
          },
          {
            id: 3, name: 'Xác nhận chốt đơn', color: '#cc6633',
          },
        ],
      },
      {
        level: 1,
        data: [
          {
            id: 3, name: 'Kế Toán mặc định', color: '#ff0099',
          },
          {
            id: 4, name: 'Trả hàng về Kho', color: '#331211',
          },
        ],
      },
      {
        level: 2,
        data: [
          {
            id: 5, name: 'Khai thác lại', color: '#000000',
          },
          {
            id: 6, name: 'Chuyển Hàng', color: '#FF0000',
          },
        ],
      },
      {
        level: 3,
        data: [
          {
            id: 7, name: 'Không Nghe Điện', color: '#337ab7',
          },
          {
            id: 8, name: 'Chuyển Hoàn', color: '#000000',
          },
        ],
      },
      {
        level: 4,
        data: [
          {
            id: 9, name: 'Thành công', color: '#996699',
          },
          {
            id: 10, name: 'Hủy', color: '#7a7980',
          },
        ],
      },
      {
        level: 6,
        data: [
          {
            id: 11, name: 'Gọi Máy Bận', color: '#dabc51',
          },
          {
            id: 12, name: 'Đã thu Tiền', color: '#00a65a',
          },
        ],
      }],
      typePayment: [
        {
          value: 1, text: 'Tiền Mặt',
        },
        {
          value: 2, text: 'Thẻ',
        },
        {
          value: 3, text: 'Chuyển Khoản',
        },
      ],
      users: {
        parentForms: {},
        InfoForm: [],
        account: {
          studentAccount: {
            username: null, password: null,
          },
          parentAccount: {
            username: null, password: null,
          },
        },
        courses: {
          items: [],
        },
        infoOrder: {
        },
        cost: {
          toMoney: 0,
          discount: 0,
          surcharge: 0,
          paid: 0,
          needToPay: 0,
          moneyType: {},
        },
        paid: {
          total: 0,
          moneyType: {},
        },
        payBill: {
          forms: [
            {
              loai: null, // api
              so_tien: 0,
              ghi_chu: '',
            },
          ],
          tong_so: 0,
        },
        book: [],
      },
      required,
      email,
    }
  },
  components: {
    HistoryAll,
    AccountForm,
    ValidationObserver,
    BCardBody,
    BButton,
    BCard,
    PrePay,
    Book,
    CostVue,
    OrderInfoVue,
    ProductInfoVue,
    CreateCustomerVue,
    PayBill,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    FeatherIcon,
    HistoryManager
  },
  beforeUnmount() {
    console.log('nếu reload');
  },
  created() {
    // fetch 
    this.users.InfoForm = {
      is_collapse: true,
      student: true,
      province: null,
      district: null,
      ward: null,
      fullNameCustomer: null,
      genderCustomer: null,
      parent: false,
      country: 'VN',
    }
    this.$http.get('/create_orders')
      .then(({ data }) => {
        if (data) {
          console.log('created order', data)
        }
      })
    document.title = 'Tạo đơn hàng mới'
  },
  methods: {
    createOrder() {
      // tạo qua thông tin đã có
      this.$refs.CreateOrder.validate().then(success => {
        if (success) {
          this.$router.replace({ name: 'orders' })
            .then(() => {
              // redux
              this.$http.post('/orders', this.users)
                .then(({ data}) => {
                  if (data.success) {
                    console.log('createOrder success', this.users)

                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Tạo Đơn Thành Công!',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'Mừng quá tạo đơn thành công rồi!',
                      },
                    })
                  } else {
                    this.$refs.CreateOrder.setErrors(data.errors)
                  }
                })
            })
        } else {
          // setError
          this.$refs.CreateOrder.setErrors({
            email: ['Email already exists!'],
          })
        }
      })
    },
    typeOptions() {
      this.users.parentForms = { 
        is_collapse: true, 
        type: 'parent',
        student: true,
        province: null,
        district: null,
        ward: null,
        fullNameCustomer: null,
        genderCustomer: null,
        parent: false,
        country: 'VN',
      }
    },
    productInfoRemoveItemKhoaHoc(price) {
      let { paid, toMoney, needToPay, surcharge } = this.users.cost
      console.log('productInfoRemoveItemKhoaHoc', price);
      needToPay -= price
      toMoney -= price
      this.users.cost = { paid, toMoney, needToPay, surcharge }
    },
    onPaidSubmit(total, moneyType) {
      console.log('onPrePaySubmit', total, moneyType, this.users.cost.paid)
      // kiểm tra needToPay trừ ra
      // this.users.cost.paid += total
      // paid = total
      let { paid, toMoney, needToPay, surcharge } = this.users.cost

      if (toMoney > 0 && toMoney >= paid) {
        paid = total
        needToPay -= paid
      }else{
        alert('Trả trước phải nhỏ hơn hoặc bằng Cần Thanh Toán')
      }
        
      this.users.cost = { paid, toMoney, needToPay, surcharge }
    },
    onPriceSelectedClass(total) {
      console.log('onPriceSelectedClass', total)
      // trừ trả trước nếu có
      let { paid, toMoney, needToPay, surcharge } = this.users.cost
      toMoney = Number(toMoney)+total
      if (paid > 0) {
        needToPay = toMoney-paid
      }else{
        needToPay = toMoney
      }
      
      this.users.cost = { paid, toMoney, needToPay, surcharge }
    },
    formInfoUserChange(t, e) {
      console.log('change', t, e)
      console.log('InfoForm', this.users.InfoForm)
    },
    removeFormInfo(kk) {
      this.users.InfoForm = this.users.InfoForm.filter((item, k) => k !== kk)
    },
    async addNewFormInfo() {
      this.addNewForm()
    },
  },
}
</script>

<style lang="scss" >
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>

.ft-card-header-create-user {
  margin-bottom: 1rem;
  padding: 1rem!important;
}
.ft-bg-brown {
  background-color: #d2d6de!important;
}
 .ft-card-not-border {
  border-radius: 0px!important;
 }
 .ft-card-header-create-user .card .card-title {
  font-size: 1.5rem!important;
 }
 .ft-form-info-user-button-align {
  margin-right: -40px;
 }
 .card .card-header {
  padding: .5rem!important;
 }
 .ft-create-customer-bottom {
  margin-bottom: .1rem;
 }
 .ft-create-customer-bottom .card {
  border-radius: 0px!important;
 }
</style>
