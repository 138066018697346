<template>
  <app-collapse accordion>
    <app-collapse-item :isVisible="true" headerBgVariant="primary" bodyBorderVariant="info">
      <template slot="header">
        <div class="d-flex align-items-center">
          <b-card-title>
            <feather-icon
              size="24"
              class="mr-1 text-white"
              icon="CalendarIcon"
            />
            <span class="text-white">Lịch Hẹn</span>
          </b-card-title>
        </div>
      </template>
      <div class="ft-filter-form">
        <validation-observer ref="OrderBookRules">
          <div class="">
            <b-row class="item-appointment">
              <b-col cols="12">
                <b-row no-gutters>
                  <b-col>
                    <b-form-group
                      label="Chọn Giờ"
                      label-for="ChooseDay"
                      label-cols-md="3"
                      required
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="time"
                        rules="required"
                        :customMessages="{['required']: 'Không được bỏ trống'}"
                      >
                        <b-form-timepicker
                          v-model='time' 
                          locale='vi'
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-row no-gutters>
                  <b-col>
                    <b-form-group
                      label="Chọn ngày"
                      label-for="ChooseDay"
                      label-cols-md="3"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="day"
                        rules="required"
                        :customMessages="{['required']: 'Không được bỏ trống'}"
                      >
                        <b-form-datepicker
                          id="ChooseDay"
                          v-model="day"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-row no-gutters>
                  <b-col>
                    <b-form-group
                      label="Ghi chú"
                      label-for="NoteDay"
                      label-cols-md="3"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="note"
                      >
                        <b-form-textarea
                          v-model='note'
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="">
            <b-button
              size="sm"
              variant="primary"
              style="margin-right: 10px;"
              @click="validationForm"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle" >Thêm lịch</span>
            </b-button>
          </div>
          <div style="padding-top: 20px">
            <b-table :fields="fields" :items="getBooksItem">
              <template #cell(act)="data">
                <b-button size="sm" variant="warning"  @click="removeDate(data.index)">
                  <feather-icon icon="XIcon" />
                </b-button>
              </template>
            </b-table>
          </div>
        </validation-observer>
      </div>
    </app-collapse-item>
  </app-collapse>
</template>
<script>

import { BTable, BButton, BRow, BCol, BCard, BFormGroup,BFormTimepicker , BFormDatepicker, BFormTextarea, BCardBody, BCardTitle, BCardHeader, } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { required } from '@validations'

export default {
  computed: {
    getBooksItem() {
      return this.items.sort((a, b) => a.timeStamp - b.timeStamp ? 1: -1)
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
  methods: {
    validationForm() {
      this.$refs.OrderBookRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          
          if (this.items.some(element => (element.time === this.time ? true : false))
              && this.items.some(element => (element.day === this.day ? true : false))) {
            // neu ton tai ra loi
            this.$refs.OrderBookRules.setErrors({
              "time": ["Thông tin này đã tồn tại"],
              "day": ["Thông tin này đã tồn tại"],
            })
            return
          }
          const timeStamp = Math.round(new Date(`${this.day}T${this.time}`).getTime() / 1000)
         
          this.items.push({
            time: this.time,
            day: this.day,
            note: this.note,
            timestamp: timeStamp,
          })
          console.log('timeStamp', timeStamp)
          this.time = null
          this.day = null
          this.note = ''
          this.timestamp = timeStamp
          this.$refs.OrderBookRules.reset()
        }
      })
    },
    showMsgBoxConfirmDelete(id) {
      // delete row
      this.$bvModal
        .msgBoxConfirm(`Có phải bạn muốn xóa dòng ${id} không?`, {
          title: 'Xác nhận',
          size: 'md',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            // xóa dòng
            this.items = this.items.filter((a, kk) => kk !== id)
          }
        })
    },
    removeDate(k) {
      this.showMsgBoxConfirmDelete(k)
    },
  },
  data() {
    return {
      required,
      fields: [
        {
          label: 'Giờ',
          key: 'time',
        },
        {
          label: 'Ngày',
          key: 'day',
        },
        {
          label: 'Ghi Chú',
          key: 'note',
        },
        {
          label: '',
          key: 'act',
        },
      ],
      time: null,
      day: null,
      note: '',
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormTimepicker ,
    BTable, 
    AppCollapse,
    AppCollapseItem,
    BButton,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormDatepicker,
    FeatherIcon,
  },
}
</script>

<style>
  #cart-body-appointment > .item-appointment {
    border: 1px solid rgb(209, 216, 209); margin: 2px; padding: 5px;
    box-shadow: 1px 1px 1px rgb(122, 118, 118);
    background: rgb(231, 230, 228);
    border-radius: 4px;
  }
</style>