<template>
  <div style="margin-bottom: 20px">
    <app-collapse accordion>
      <app-collapse-item :isVisible="true" headerBgVariant="success" bodyBorderVariant="info">
        <template slot="header">
          <div class="d-flex align-items-center">
            <feather-icon
              size="24"
              class="mr-1  text-white"
              icon="DollarSignIcon"
            />
            <b-card-title class="text-white">
              Chi Phí (vnđ)
            </b-card-title>
          </div>
        </template>
        <div class="ft-filter-form ft-fix-top-card-cost">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Thành Tiền"
                label-cols-md="4"
              >
                <cleave
                  v-model="money.toMoney"
                  class="form-control"
                  disabled
                  :raw="true"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                  }"
                />
              </b-form-group>
              <b-form-group
                label="Giảm Giá"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="money.discount"
                  class="form-control"
                  @change="onChangeDisCount"
                />
              </b-form-group>
              <b-form-group
                label="Phụ Thu"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="money.surcharge"
                  @change="onChangeSurcharge"
                />
              </b-form-group>
              <b-form-group
                label="Trả Trước"
                label-cols-md="4"
              >
                <cleave
                  v-model="money.paid"
                  class="form-control"
                  disabled
                  :raw="true"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                  }"
                />
              </b-form-group>
              <hr/>
              <b-form-group
                label="Cần Thanh Toán"
                label-cols-md="4"
              >
                <cleave
                  v-model="money.needToPay"
                  class="form-control"
                  disabled
                  :raw="true"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import { BCardTitle, BRow, BCol, BFormGroup, BFormInput } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Cleave from 'vue-cleave-component'

export default {
  methods: {
    onChangeDisCount(e) {
      let needToPay = Number(this.money.needToPay)
      let discount = Number(this.money.discount)
      console.log('onChangeDisCount', needToPay, discount)
      if (discount > needToPay) {
        alert('Số tiền phải bé hơn thành tiền')
      } else {
        needToPay -= discount
        this.money.needToPay = needToPay
      }
    },
    onChangeSurcharge(e) {
      let needToPay = Number(this.money.needToPay)
      let surcharge = Number(this.money.surcharge)
      console.log('onChangeSurcharge', needToPay, surcharge)
      if (surcharge > needToPay) {
        needToPay += surcharge
        this.money.needToPay = needToPay
      }
    },
  },
  props: {
    money: {
      type: Object,
      required: true,
    },
  },
  components: {
    BFormInput,
    Cleave,
    AppCollapse,
    AppCollapseItem,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
  },
}
</script>

<style scoped>
  .form-control {
    text-align: right;
  }
</style>
<style>
  .ft-fix-top-card-cost {
    padding-top: -10px;
  }
</style>