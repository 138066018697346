<template>
  <b-modal
    id="modal-2"
    size="lg"
    title="Tạo phiếu thanh toán"
    hide-footer
  >
    <b-container fluid>
      <div>
        <b-row>
          <b-col cols="9">
            <b-form
              ref="form"
              :style="{height: trHeight}"
              class="repeater-form"
              @submit.prevent="repeateAgain"
            >
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="index"
                ref="row"
                no-gutters
              >

                <!-- Item Name -->
                <b-col md="3">
                  <b-form-group
                    label="Loại"
                    label-for="item-name"
                  >
                    <b-form-select
                      v-model="item.selected"
                      :options="loai_chuyen_khoang"
                      @change="onChange"
                    />
                  </b-form-group>
                </b-col>

                <!-- Cost -->
                <b-col md="4">
                  <b-form-group
                    label="Số tiền"
                    label-for="cost"
                  >
                    <b-form-input
                      @change="onChange"
                      type="number"
                      v-model="item.amount"
                    />
                  </b-form-group>
                </b-col>

                <!-- Quantity -->
                <b-col md="4">
                  <b-form-group
                    label="Ghi chú"
                    label-for="note"
                  >
                    <b-form-input
                      id="note"
                    />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  cols="1"
                >
                  <b-button
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
          <b-col cols="3">
            <div class="ft-pay-bill">
              <div class="title right">Thành Tiền</div>
              <b-row no-gutters>
                <b-col>
                  <div v-for="(item, k) in loai_chuyen_khoang" class="right" :key="k">
                    {{ item.text }}:
                  </div>
                </b-col>
                <b-col>
                  <div v-for="(item, k) in loai_chuyen_khoang" class="right" :key="k">
                    {{ formatStringToMoney(money_c[item.value]) }}đ
                  </div>
                </b-col>
              </b-row>
              <hr/>
              <b-row no-gutters>
                <b-col>
                  <div class="right text-bold">Tổng Tiền:</div>
                </b-col>
                <b-col>
                  <div class="right text-bold">{{ total_money.toLocaleString() }}đ</div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
        style="margin-right: 5px"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Add New</span>
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        @click="saveAmount"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-25"
        />
        <span>Save</span>
      </b-button>
    </b-container>
  </b-modal>
</template>

<script>
import { BFormSelect, BContainer, BForm, BRow, BCol, BModal, BButton, BFormGroup, BFormInput } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Cleave from 'vue-cleave-component'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  props: {
    loaiChuyenKhoang: {
      type: Array,
      required: true,
    },
  },
  components: {
    Cleave,
    BFormSelect,
    BContainer,
    FeatherIcon,
    BModal,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        id: 1,
        selected: 1,
        amount: null,
      }],
      money_c: {},
      total_money: 0,
      nextTodoId: 2,
      loai_chuyen_khoang: this.loaiChuyenKhoang,
    }
  },
  mounted() {
    this.initTrHeight()
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'modal-2') {
        this.items = [{
          id: 1,
          selected: 1,
          amount: null,
        }]
        this.total_money = 0
        this.money_c = {}
        this.nextTodoId = 2
      }
    })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    formatStringToMoney(data) {
      return typeof data != 'undefined' ? Number(data).toLocaleString() : 0
    },
    saveAmount() {
      this.$emit('save-amount', this.total_money, this.money_c)
    },
    onChange() {
      // an vao save se cong tat ca so tien
      this.money_c = {}
      this.total_money = 0
      this.items.forEach(element => {
        const amount = Number(element.amount)
        this.total_money += amount
        if (this.money_c.hasOwnProperty(element.selected))
        {
          this.money_c[element.selected] += amount
        } else {
          this.money_c[element.selected] = amount
        }
      })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId = +this.nextTodoId,
        selected: 1,
        amount: null,
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style>
  .ft-pay-bill .title {
    font-size: 20px;
  }
  .ft-pay-bill .right {
    text-align: right;
  }
  .ft-pay-bill .text-bold {
    font-weight: bold;
  }
</style>
