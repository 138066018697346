<template>
  <app-collapse accordion>
    <app-collapse-item :isVisible="true" headerStyle="background-color: #d2d6de">
      <template slot="header">
        <div class="d-flex align-items-center">
          <b-card-title class="ml-25"><feather-icon
            size="24"
            class="mr-1"
            icon="Edit3Icon"
          />
            <span>Lịch Sử Chỉnh Sửa</span>
          </b-card-title>
        </div>
      </template>
      <div class="ft-filter-form">
      <b-row>
        <b-col cols="12">
          <b-table
            :fields="fields"
            :items="rows"
            selectable
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
          >
            <template #cell(act)="data">
              <b-button variant="info" size="sm" :to="{ name: 'manager-classes-edit', params: { id: data.item.id }}""><feather-icon icon="EditIcon" size="20" /></b-button>
              <b-button variant="warning" size="sm" @click="showMsgBoxConfirmDelete(data.index)"><feather-icon icon="XIcon" size="20" /></b-button>
            </template>
            <template #cell(start_date)="data">
                {{ getDateByRow(data.item.start_date) }}
            </template>
            <template #cell(studen_in)="data">
                <div style="text-align: right">{{ data.item.studen_in }}</div>
            </template>
            <template #cell(price)="data">
                {{ data.item.price.toLocaleString() }}đ
            </template>
            <template #cell(end_date)="data">
                {{ getDateByRow(data.item.end_date)}}
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rowsLength"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    </app-collapse-item>
  </app-collapse>
</template>

<script>

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BButton, BCol, BRow, BCardBody, BTable,BCardTitle, BPagination, BCard, BCardHeader,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { formatDate } from '@/@core/utils/filter'

export default {
  props: {
    data: {
      type: Array,
      required: true,
      default: [],
    }
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BButton,
    BCol,
    BRow,
    BCardBody,
    BTable,
    BPagination,
    BCardHeader,
    BPagination,
    BCardTitle,
    BCard,
    FeatherIcon
},
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'Thời Gian',
          key: 'created_at',
        },
        {
          label: 'Người Chỉnh Sửa',
          key: 'user_edit',
        },
        {
          label: 'Thông Tin Chỉnh sửa',
          key: 'info_edit',
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterKhoaHoc: null,
      filterLopHoc: null,
      filterGiaoVien: null,
      filterTeacherAt: null,
      filter: null,
      filterOn: ['khoa_hoc'], // danh sách cột
      count: 0,
      filterDateFrom: null,
      filterDateTo: null,
      selected: [],
      items: [
        {
          id: 1,
          created_at: '27/07/2022',
          user_edit: 'Quốc Dũng',
          info_edit: 'Sửa thông tin khách hàng',
        },
        {
          id: 1,
          created_at: '27/07/2022',
          user_edit: 'Quốc Dũng',
          info_edit: 'Sửa thông tin khách hàng',
        },
        {
          id: 1,
          created_at: '27/07/2022',
          user_edit: 'Quốc Dũng',
          info_edit: 'Sửa thông tin khách hàng',
        },
        {
          id: 1,
          created_at: '27/07/2022',
          user_edit: 'Quốc Dũng',
          info_edit: 'Sửa thông tin khách hàng',
        },
        {
          id: 1,
          created_at: '27/07/2022',
          user_edit: 'Quốc Dũng',
          info_edit: 'Sửa thông tin khách hàng',
        },
        {
          id: 1,
          created_at: '27/07/2022',
          user_edit: 'Quốc Dũng',
          info_edit: 'Sửa thông tin khách hàng',
        },
        {
          id: 1,
          created_at: '27/07/2022',
          user_edit: 'Quốc Dũng',
          info_edit: 'Sửa thông tin khách hàng',
        },
      ],
    }
  },
  
  computed: {
    rowsLength() {
      return this.items.length;
    },
    rows() {
      return this.items
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    onRowSelected() {
      
    }
  },
  methods: {
    getDateByRow(UNIX_timestamp){
      return formatDate(UNIX_timestamp * 1000, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    },
  },
}
</script>
