<template>
  <app-collapse accordion>
    <app-collapse-item :isVisible="true" headerStyle="background-color: #d2d6de">
      <template slot="header">
        <div class="d-flex align-items-center">
          <b-card-title class="ml-25"><feather-icon
            size="24"
            class="mr-1"
            icon="InfoIcon"
          />
            <span>Thông Tin Đăng Ký</span>
          </b-card-title>
        </div>
      </template>
      <div class="ft-filter-form">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Trạng Thái"
              label-for="status"
              label-cols-md="4"
            >
              <b-button size="sm" :style="'background-color:' + statusH.color + '!important;border:unset;margin-right: 5px;border-radius: 10px'">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                  size="10"
                />
                {{ statusH.name }}</b-button>
              <b-button size="sm" v-b-modal.modal-1 variant="primary">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="10"
                />Thay đổi</b-button>
            </b-form-group>
            <b-form-group
              label="Phân Loại Sản Phẩm"
              label-for="sale"
              label-cols-md="4"
            >
                <b-form-select
                  v-model="form.type_product"
                  :options="data.typeProductOptions"
                />
            </b-form-group>
            <hr/>
            <b-row>
              <b-col>
                <b-form-group
                  label="Nguồn MKT"
                  label-for="sale"
                  label-cols-md="4"
                >
                  <b-form-select v-model="form.mkt_source" :options="data.orderSourceOptions" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              label="Người Tạo Đăng Ký"
              label-for="sale"
              label-cols-md="4"
            >
              <b-form-select v-model="form.creater_order" :options="data.createrOptions" />
            </b-form-group>
            <b-form-group
              label="Chia Đơn Cho"
              label-for="addamount"
              label-cols-md="4"
            >
              <b-form-select v-model="form.manager_order" :options="data.users" />
            </b-form-group>
            <b-form-group
              label="Nguồn Up Đăng ký"
              label-for="total"
              label-cols-md="4"
            >
              <b-form-select v-model="form.up_sale_reg" :options="data.up_sale" />
            </b-form-group>
            <b-form-group
              label="Lý Do Hủy / Xem Xét Lại"
              label-for="note_cancel"
            >
              <b-form-textarea
                id="note_cancel"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <edit-status @selectstatus="onSelectedStatus" :statusOptions="statusOptions" />
    </app-collapse-item>
  </app-collapse>
</template>

<script>

import { BCardTitle, BRow, BCol, BFormGroup, BButton, BFormTextarea, BFormSelect, BFormSelectOption } from 'bootstrap-vue'
import constants from '@core/app-config/constants'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import EditStatus from '../modal/EditStatus.vue'
import { ValidationObserver } from 'vee-validate'

export default {
  data () {
    return {
      statusH: {}
    }
  },
  props: {
    statusOptions: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    }
  },
  methods: {
    onSelectedStatus(val) {
      this.form.status = val.id
      console.log('statusH', val)
      this.statusH = val
      // this.$emit('on-data-update', val)
    },
  },
  created() {
    this.statusH = this.statusOptions[0].data[1]
    this.form.status = this.statusH.id
  },
  computed: {
    Option() {
      return constants
    },
  },
  components: {
    ValidationObserver,
    EditStatus,
    AppCollapse,
    AppCollapseItem,
    BCardTitle,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BFormGroup,
    BButton,
  },
}
</script>

<style>

</style>