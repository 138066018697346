<template>
  <div class="ft-status-modal">
    <b-modal
      id="modal-1"
      size="lg"
      title="Chọn trạng thái đơn hàng"
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-col
            v-for="level in statusOptions"
            :key="level.level"
            cols="2"
            lg="2"
            md="3"
            sm="6"
          >
            <b-card class="iitem" no-body>
              <b-card-header style="background-color: rgb(197 192 93)">
                <b-card-title style="color: white">
                  Level {{ level.level }}
                </b-card-title>
              </b-card-header>
              <b-card-body style="border: 1px solid rgb(197 192 93);padding: unset">
                <div
                  v-for="lvOrder in level.data"
                  :key="lvOrder.id"
                >
                  <div
                    class="item"
                    @click="lvFunc(lvOrder)"
                    :value="lvOrder.id"
                    :style="'color: '+ lvOrder.color + ' ;border-left: 5px solid ' + lvOrder.color"
                  ><span><feather-icon icon="CheckSquareIcon" size="15" />{{ lvOrder.name }}</span></div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { BCardHeader, BCardBody, BCardTitle, BContainer, BRow, BCol, BModal, BCard } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  props: {
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    lvFunc(val) {
      this.$emit('selectstatus', val)
      this.$root.$emit('bv::hide::modal', 'modal-1', '#focusThisOnClose')
    },
  },
  data() {
    return {
      lv: 0,
    }
  },
  components: {
    BCardHeader, BCardBody, BCardTitle,
    BContainer,
    BRow,
    BCol,
    BModal,
    FeatherIcon,
    BCard,
  },
}
</script>
  
<style>
.modal-lg {
  max-width: 1450px;
  margin: 2rem auto;
}
.modal-title {
  font-size: 30px;
}
.item:hover {
  background-color: rgb(199, 204, 159);
  color: white;
}
.item {
  cursor: pointer;font-size: 14px;border:1px solid rgb(213, 223, 84); margin: 3px;
}
.iitem {
  height: 100%;
  font-size: 1rem;
  border-radius: 5px!important;
}
.iitem .name {
  font-size: 16px;
}
</style>
